import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
//Cadastros
const BancoDados = React.lazy(() => import('./views/pages/bancodados/BancoDados'))
const Mensagem = React.lazy(() => import('./views/pages/mensagem/Mensagem'))
const MensagemCreate = React.lazy(() => import('./views/pages/mensagem/MensagemCreate'))
const VPN = React.lazy(() => import('./views/pages/vpn/Vpn'))
const FTP = React.lazy(() => import('./views/pages/ftp/Ftp'))
const page404 = React.lazy(() => import('./views/pages/page404/Page404'))

//Admin
const Empresa = React.lazy(() => import('./views/pages/empresa/Empresa'))
const User = React.lazy(() => import('./views/pages/user/User'))
const API = React.lazy(() => import('./views/pages/api/Api'))
const Phones = React.lazy(() => import('./views/pages/phones/Phone'))
const Integracao = React.lazy(() => import('./views/pages/integracoes/Integracao'))
const IntegracaoCreate = React.lazy(() => import('./views/pages/integracoes/IntegracaoCreate'))
const IntegracaoLog = React.lazy(() => import('./views/pages/integracoes/IntegracaoLog'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '*', name: '404', element: page404 },
  { path: '/bancodados', name: 'Banco de Dados', element: BancoDados },
  { path: '/ftp', name: 'FTP', element: FTP },
  { path: '/vpn', name: 'VPN', element: VPN },
  { path: '/mensagem', name: 'Mensagem', element: Mensagem },
  { path: '/mensagem/create', name: 'Nova mensagem', element: MensagemCreate },
  { path: '/mensagem/:id', name: 'MensagemEdit', element: MensagemCreate },
  { path: '/cadastros/empresas', name: 'Empresa', element: Empresa, exact: true },
  { path: '/cadastros/users', name: 'User', element: User },
  { path: '/cadastros/api', name: 'API', element: API },
  { path: '/phones', name: 'Números', element: Phones },
  { path: '/integracao', name: 'Integrações', element: Integracao },
  { path: '/integracao/logs/:id', name: 'Logs da Integração', element: IntegracaoLog },
  { path: '/integracao/create', name: 'Cadastro de Integração', element: IntegracaoCreate },
  { path: '/integracao/:id', name: 'Cadastro de Integração', element: IntegracaoCreate },
]

export default routes
