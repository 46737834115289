import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilLibraryBuilding,
  cilPuzzle,
  cilUserPlus,
  cilApplications,
  cilEnvelopeClosed,
  cilHome,
  cilRss,
  cilFolderOpen,
  cibWhatsapp,
  cilChatBubble,
} from '@coreui/icons'
import { CNavItem, CNavTitle } from '@coreui/react'

const navigation_admin = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Cadastros',
  },
  {
    component: CNavItem,
    name: 'Mensagens',
    to: '/mensagem',
    icon: <CIcon icon={cilEnvelopeClosed} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Integrações',
    to: '/integracao',
    icon: <CIcon icon={cilChatBubble} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Banco de Dados',
    to: '/bancodados',
    icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'FTP',
    to: '/ftp',
    icon: <CIcon icon={cilFolderOpen} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'VPN',
    to: '/vpn',
    icon: <CIcon icon={cilRss} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Configurações',
  },
  {
    component: CNavItem,
    name: 'Números',
    to: '/phones',
    icon: <CIcon icon={cibWhatsapp} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Administração',
  },
  {
    component: CNavItem,
    name: 'Empresas',
    icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
    to: '/cadastros/empresas',
  },
  {
    component: CNavItem,
    name: 'Usuários',
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    to: '/cadastros/users',
  },
  {
    component: CNavItem,
    name: 'APIs',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    to: '/cadastros/api',
  }
]

export default navigation_admin
